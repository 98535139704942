<template>
<div>
  
                        
      <div class="rounded-lg shadow border border-white bg-white">
            <div class="row justify-content-centre align-items-center">    

            <h5 class="col ml-3 font-weight-lighter text-info"> <span class="fa fa-file-alt"></span> Commandes POS {{ResivedNom}} ({{this.currentNom}})</h5> 


                    <div class="col">
         

                                    <b-button pill size="sm"  class="m-1 float-right text-secondary" variant="link" @click="NewBon">
                                                <i class="fa fa-home fa-2x"></i>
                                    </b-button>

                                    <b-button pill size="sm"  class="m-2 float-right" variant="info" @click="fetchList">
                                        <i class="fa fa-sync-alt"></i>
                                        <span> Actualiser</span> 
                                    </b-button>


                                    <b-button v-if="ResivedType==1 && d_gst_tresor==true && d_gst_paie_pos==true" pill size="sm"  class="m-2 float-right" variant="success" @click="fetchFormComptes_valider">
                                        <i class="fa fa-plus-square"></i>
                                        <span> Valider Paiement</span> 
                                    </b-button>
                    </div>
            </div>
       



        <b-table 
                id="main-table"
        :per-page="perPage"
        :current-page="currentPage"

        sort-icon-left
        head-variant="light"

        :fields="fields" 
        :items="itemslist"
        >
            <template #cell(actions)="row">
                    <b-button v-if="ResivedType==0" pill size="sm"  class="mr-2 float-right" variant="outline-danger" @click="del_click(row.item)">
                                <i class="fa fa-trash-alt"></i>
                        
                    </b-button>
                    <b-button v-if="ResivedType==0" pill size="sm" class="mr-2 float-right" variant="outline-primary" @click="edit_click(row.item)">
                            <i class="fa fa-pencil-alt"></i>
                    
                    </b-button>
                    <b-button v-if="ResivedType==1" pill size="sm"  class="mr-2 float-right" variant="outline-warning" @click="devald_click(row.item)">
                                <i class="fa fa-check"></i>
                        
                    </b-button>
                    <b-button pill size="sm" class="mr-2 float-right" variant="outline-info" @click="fetchListArts(row.item)">
                            <i class="fa fa-bars"></i>
                    
                    </b-button>
                </template>
        
        </b-table>
        
                <b-pagination
                pills
                v-model="currentPage"
                :total-rows="rows_pagin"
                :per-page="perPage"
                aria-controls="main-table"
                align="center"
                ></b-pagination>



      </div>

                <div class="card border-left-success shadow mt-3">
                <div class="card-body p-0">

                        <div class=" d-flex justify-content-center pt-1 text-secondary">
                                <h4 class="font-weight-normal p-2">TOTAL : {{total_general}} DH</h4>
                        </div>

                </div>
              </div>

    <!-- le modal -->
    <b-modal id="detailModal" :title=curentBN size="lg" hide-footer>


       <b-table 
        small 
        borderless
        striped 
        sticky-header
        :fields="fieldsArticle" 
        :items="ArticlesList"
        >

        
        </b-table>
              <div class="card border-info">
                        <div class=" d-flex justify-content-center pt-1 text-info">
                                <h5 class="font-weight-light ">Total Prix : {{total_price}} </h5>
                        </div>
              </div>
    </b-modal>




    <!-- le modal -->
    <b-modal id="validerModal" title="Le paiement" hide-footer>
        <form @submit.prevent="handleSubmitReglerBn">

          <div class="mt-3 ml-2  text-secondary font-weight-light">Total Net (DH) : 
          <span class="d-inline text-info"> {{total_general}} </span>  </div>

            <div class="form-group">
                            <label for="bank_compte_id">Compte :</label>
                            <select class="form-control" id="bank_compte_id" v-model="itemVal.bank_compte_id">
                            <option v-for="item in comptes" :key="item.id" v-bind:value="item.id">   
                                {{ item.nom }}
                            </option>
                            </select>

            </div>




            <div class="form-group">
                <button type="submit" class="btn  btn-primary btn-block mt-4">Valider</button>
            </div>
        </form>
    </b-modal>


    <!-- les trucs public -->

    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>


</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required} from "vuelidate/lib/validators";

export default {
    props: ['ResivedType','ResivedCaisse','ResivedDepot','ResivedNom'],
      computed: {
              d_gst_paie_pos() {
      return this.$store.getters.get_gst_paie_pos;
    },
        d_gst_tresor() {
      return this.$store.getters.get_gst_tresor;
    },
      user_name() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.name;
      }
      else
      {
            return '';
      }
    },
    total_general: function() {
      return Number(this.itemslist.reduce((a, b) => +a + +b.total, 0)).toFixed(2);
    },
    total_price: function() {
      return this.ArticlesList.reduce((a, b) => +a + +(b.qte * b.prix), 0);
    },
      rows_pagin() {
        return this.itemslist.length
      }
      },
    mounted(){

            if(this.ResivedType == 0)
            {
                this.currentNom="Brouillons"
            }
            else if(this.ResivedType == 1)
            {
                this.currentNom="Validées"
            }
            else if(this.ResivedType == 2)
            {
                this.currentNom="Réglées /Archivées"
            }
        this.fetchList()
    },
	data () {
		return {
            itemVal: {
                date:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                bank_compte_id:'',
                    },
            fieldsArticle: [
                {key: 'ref', label: 'Réf'},
                {key: 'qte', label: 'Quantité'},
                {key: 'prix', label: 'Prix'},
                {key: 'description', label: 'Description'}
            ],
            ArticlesList:[],


            currentNom:'',
            curentBN:'',
            perPage: 10,
            currentPage: 1,
            itemslist: [],

            fields: [
				{key: 'nom', label: 'Nom', sortable: true},
                {key: 'date', label: 'Date', sortable: true},
                {key: 'client', label: 'Client',  sortable: true},
                {key: 'total', label: 'Total TTC',  sortable: true},
                {key: 'description', label: 'Description', sortable: true},
                {key: 'user', label: 'Utilisateur', sortable: true},
                { key: 'actions', label: '' }
            ],
            comptes:[],
            //public trucs

            isLoading: false
		}
	},
    validations: {
            itemForm: {
                nom: {
                    required
                }
            }
    },
    methods :{

        fetchFormComptes_valider: function () {
            //comptes :
                    this.isLoading=true;
                    this.$http.get('/bankComptes')
                    .then((result) => {
                                this.comptes = result.data.data
                                this.isLoading=false;

                                 this.$root.$emit('bv::show::modal', 'validerModal')
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },

        fetchListArts(row_bn)
        {
                    this.isLoading=true;
                    this.$http.get('/ventCmd/'+row_bn.id)
                    .then((result) => {
                                this.ArticlesList = result.data.data
                                this.isLoading=false;


                                this.curentBN=row_bn.nom + " ( "+row_bn.date+" )"
                                this.$root.$emit('bv::show::modal', 'detailModal')

                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });


        },
        edit_click(item) {

                this.$confirm("Voulez-vous vraiment modifier ce Bon ??")
                 .then(() => {


                        this.$router.push({ 
                        name: 'posPage', 
                        params: { 
                        ResivedId: this.ResivedCaisse,
                        ResivedDepot:this.ResivedDepot,
                        ResivedNom:this.ResivedNom,
                        ResivedCmdId:item.id
                       } 
});

                })
                .catch(error=> {
                console.log(error)

                });


        },
        del_click(item) {

                                    this.$confirm("Voulez-vous vraiment supprimer ce Bon??")
                                    .then(() => {

                                        this.BnDell(item.id)
                                    })
                                    .catch(error=> {
                                    console.log(error)

                                        });




        },
        fetchList: function () {
                    this.isLoading=true;
                    this.$http.get('/listCmdType/'+this.ResivedType+'/'+this.ResivedCaisse)
                    .then((result) => {
                                this.itemslist = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
        BnDell(id_bn){

            this.isLoading=true;

            this.$http.delete('/posCmd/'+id_bn)
                .then(response => {
                console.log(response.data)
                this.isLoading=false;   
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })


                    let index = this.itemslist.findIndex(item => item.id === id_bn)
                    this.itemslist.splice(index, 1)




                    })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });

        },
        NewBon()
        {
                    this.$router.push({ 
                        name: 'posMenu'
                    });
        },
         devald_click(item) {

                                    this.$confirm("Voulez-vous vraiment Devalider ce Bon??")
                                    .then(() => {

                                                    this.isLoading=true;

                                                    this.$http.get('/validCmd/'+item.id+'/0')
                                                        .then(response => {
                                                        console.log(response.data)
                                                        this.isLoading=false;   
                                                        this.flashMessage.show({
                                                        status: 'success',
                                                        title: 'Bon Devalidé',
                                                        message: 'Les données sont devalidées correctement'
                                                        })
                                                                            let index = this.itemslist.findIndex(item => item.id === item.id)
                                                                            this.itemslist.splice(index, 1)



                                                            })
                                                        .catch(error=> {console.log(error)
                                                        this.isLoading=false;


                                                        this.flashMessage.show({
                                                        status: 'error',
                                                        title: 'Erreur De Suppression',
                                                        message: 'Impossible  de devalider les données .. Réessayez !!'
                                                        })
                                                        });
                                    })
                                    .catch(error=> {
                                    console.log(error)

                                        });




        },
        handleSubmitReglerBn()
        {

              if(!this.itemVal.bank_compte_id)
              {
                this.flashMessage.show({
                status: 'info',
                title: 'Sélectionnez un compte !!',
                })   
              }
            else
            {


  
                    //procedure du modification
                                this.isLoading=true;

                                this.$http.post('/validerPaiemCMD',{
                                            date:this.itemVal.date,  
                                            caisse: this.ResivedCaisse,
                                            user:this.user_name,
                                            bank_compte_id:this.itemVal.bank_compte_id,
                                            nom_caisse:this.ResivedNom
                                    })
                                    .then(response => {

                                        this.isLoading=false;
                                        console.log(response.data)
                                        this.$bvModal.hide('validerModal')


                                            //refresh the truck
                                            this.fetchList();
 
                                        this.flashMessage.show({
                                        status: 'success',
                                        title: 'Données modifiées',
                                        message: 'Les données sont modifiées correctement'
                                        })


                                        })
                                        .catch(error=> {console.log(error)
                                        this.isLoading=false;


                                        this.flashMessage.show({
                                        status: 'error',
                                        title: 'Erreur De Modification',
                                        message: 'Impossible  de modifier les données .. Réessayez !!'
                                    })
                                    });
                }




        },


    },
    components: {
              Loading      
    }
}
</script>

<style  scoped>

</style>