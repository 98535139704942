<template>
<div class="p-4 back_princ">

<div class=" d-flex justify-content-center text-info">
 <i class="fas fa-cash-register fa-4x"></i>
</div>

<div class=" d-flex justify-content-center text-info mb-4">
 <h2 class="font-weight-lighter">Points De Vente</h2>
</div>



             <div v-for="itemcat in listCaisse" :key="itemcat.id"  class="row mb-4">
                   <div class="card w-100 p-3">

                                <div class=" row p-2 m-2">

                                        <!-- Earnings (Monthly) Card Example  -->
                                        <div class="col">
                                        <div class="btn card border border-light back_btn" @click="click_pos(itemcat)">
                                           
                                            <div class="row align-items-center pt-2 pb-2">
                                                <div class="col">
                                                <div class="font-weight-bold text-secondary  text-uppercase ">{{itemcat.nom}}</div>
                                                    <div class="text-xs font-weight-normal text-secondary">{{itemcat.depot}}</div>
                                                </div>
                                                <div class="col-auto">
                                                <i class="fas fa-cash-register fa-2x text-secondary"></i>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        </div>

                                </div>





                                <div class=" row p-2 m-2">

                                        <!-- Earnings (Monthly) Card Example  -->
                                        <div class="col">
                                        <div class="btn card border border-light back_btn" @click="click_agripos(itemcat)">
                                           
                                            <div class="row align-items-center pt-2 pb-2">
                                                <div class="col">
                                                <div class="font-weight-bold text-secondary  text-uppercase ">Services Agriculteur</div>
                                                </div>
                                                <div class="col-auto">
                                                <i class="fas fa-user-edit fa-2x text-secondary"></i>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        </div>
                                        
                                        <!-- Earnings (Monthly) Card Example  -->
                                        <div class="col">
                                        <div class="btn card border border-light back_btn" @click="click_stock()">
                                           
                                            <div class="row align-items-center pt-2 pb-2">
                                                <div class="col">
                                                <div class="font-weight-bold text-secondary  text-uppercase ">Stock</div>
                                                </div>
                                                <div class="col-auto">
                                                <i class="fas fa-boxes fa-2x text-secondary"></i>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        </div>

                                </div>

                                <div class=" row p-2 m-2">

                                            <!-- Earnings (Monthly) Card Example  -->
                                            <div class="col">
                                            <div class="btn card border border-light shadow" @click="click_brou(itemcat)">
                                               
                                                <div class="row  pt-2 pb-2 align-items-center">
                                                    <div class="col">
                                                    <div class="text-xs font-weight-normal text-secondary">Bons <span class="text-warning">( Brouillon ) </span></div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            </div>

                                            <!-- Earnings (Monthly) Card Example  -->
                                            <div class="col">
                                            <div class="btn card border border-light shadow" @click="click_valid(itemcat)">
                                               
                                                <div class="row  pt-2 pb-2 align-items-center">
                                                    <div class="col">
                                                    <div class="text-xs font-weight-normal text-secondary">Bons <span class="text-primary">( Validés ) </span></div>
                                                    </div>
                                                </div>
                                              
                                            </div>
                                            </div>

                                            
                                            <!-- Earnings (Monthly) Card Example  -->
                                            <div class="col">
                                            <div class="btn card border border-light shadow" @click="click_regl(itemcat)">
                                                 
                                                <div class="row  pt-2 pb-2 align-items-center">
                                                    <div class="col">
                                                    <div class="text-xs font-weight-normal text-secondary">Bons <span class="text-success">( Réglés ) </span></div>
                                                    </div>

                                                </div>
                                              
                                            </div>
                                            </div>



                                </div>
                    </div>
             </div>







    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>


  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
    computed:{
                  user_dep() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.dep;
      }
      else
      {
            return 0;
      }
        },
    },
    mounted(){

            this.fetchList()
    },
    data () {
		return {

             listCaisse: [],

            isLoading: false
		}
	},
 methods:{
        fetchList: function () {

                    this.isLoading=true;
                    this.$http.get('/caissePrDepot/'+this.user_dep)
                    .then((result) => {
                                this.listCaisse = result.data.data
                                this.isLoading=false;

                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
       click_pos(pos)
      {

                        this.$router.push({ 
                        name: 'posPage', 
                        params: { 
                        ResivedId: pos.id,
                        ResivedDepot:pos.depot_id,
                        ResivedNom:pos.nom
                        } 
                        });

      },

       click_brou(pos)
      {

                        this.$router.push({ 
                        name: 'listeCMDs', 
                        params: { 
                        ResivedType: 0,
                        ResivedCaisse:pos.id,
                        ResivedDepot:pos.depot_id,
                        ResivedNom:pos.nom
                        } 
                        });

      },
       click_valid(pos)
      {

                        this.$router.push({ 
                        name: 'listeCMDs', 
                        params: { 
                        ResivedType: 1,
                        ResivedCaisse:pos.id,
                        ResivedDepot:pos.depot_id,
                        ResivedNom:pos.nom
                        } 
                        });

      },
       click_regl(pos)
      {

                        this.$router.push({ 
                        name: 'listeCMDs', 
                        params: { 
                        ResivedType: 2,
                        ResivedCaisse:pos.id,
                        ResivedDepot:pos.depot_id,
                        ResivedNom:pos.nom
                        } 
                        });

      },
        click_stock()
        {
                    this.$router.push({ 
                        name: 'liststockLotsRapp'

                    });
        },

        click_agripos(pos)
        {
                        this.$router.push({ 
                        name: 'agriServicePOS', 
                        params: { 
                        ResivedDepot:pos.depot_id,
                        } 
                        });
        }



 },
     components: {
              Loading      
    }
}
</script>
<style scoped>
.back_btn{
  background: #ededed;
}

.back_princ{
  background: #eff7fa;
}

.text_org{
  color: green;
}
</style>