<template>
  

<div  class="card  shadow border border-white bg-white h-100 pb-3 overflow-auto">
        <div class="row pb-1 border-bottom border-info m-3">


                <h4 class="font-weight-lighter text-info"> <span class="fa fa-folder-open"></span> Services Des Agriculteurs</h4> 
            
        </div>

<div class="row card-body p-0 m-2 h-100">
  
        <div class="col-3 h-100 m-0 p-0">

               <div class="card border-info h-100 overflow-auto">


        <form @submit.prevent="handleSubmitBonCMD()" class="bg-info">
<div class="input-group  border border-info rounded bg-info p-1 col">
                                            <select class="form-control border border-info BgSearch " id="mois" v-model="itemFormBon.mois">
                                            <option value=1>Janvier</option>
                                            <option value=2>Février</option>
                                            <option value=3>Mars</option>
                                            <option value=4>Avril</option>
                                            <option value=5>Mai</option>
                                            <option value=6>Juin</option>
                                            <option value=7>Juillet</option>
                                            <option value=8>Août</option>
                                            <option value=9>Septembre</option>
                                            <option value=10>Octobre</option>
                                            <option value=11>Novembre</option>
                                            <option value=12>Décembre</option>
                                            </select>
</div>


                <div class="input-group  border border-info rounded bg-info p-1 col">
                                            <select class="form-control border border-info BgSearch" id="centre_id" v-model="itemFormBon.centre_id">
                                            <option v-for="item in centres" :key="item.id" v-bind:value="item.id">   
                                                {{ item.nom }}
                                            </option>

                                            </select>


                                    <div class="input-group-prepend">
                                        <button class="btn  btn-light BgSearch border border-info rounded-right ml-1" type="submit" id="button-addon1">   
                                        <span class="fa fa-search"></span> Liste
                                        </button>
                                    </div>


                </div>
        </form>



                            <div class="card-header  bg-info border-info p-1">
                                <b-form-input v-model="serchAgri"  class="border-info " id="search-art" size="sm" placeholder="Chercher ..." type="search"></b-form-input>
                            
                                        <div class="custom-control custom-switch form-control-sm ml-1">
                                        <input type="checkbox"
                                        class="custom-control-input" 
                                        id="isCINBarreSearch"
                                                v-model="isCINBarreSearch"
                                                name="isCINBarreSearch"
                                                value=true
                                                unchecked-value=false>

                                        <label class="custom-control-label text-white  mb-0" for="isCINBarreSearch">Recherche Par CIN</label>
                                        </div>
                            </div>

                            <b-list-group flush  >
                       
                            <b-list-group-item  v-for="item in filteragrie" :key="item.id" button class="p-3" variant="info" @click="agriculteur_click(item.id)">
                                 {{ item.nom }} -  {{ item.cin }}





                            </b-list-group-item>                          

                            </b-list-group>
               </div>
        </div> 


            <!-- LA PARTIE DES CARDES -->
        <div v-if="itemFormBon.agriculteur_id" class="col-9  mr-auto ">






<!-- parti add service -->
          <div class="row mb-3 h-100">
          <div class="col-4 h-100">
                                                      <div v-for="itemart in listArtCaisse" :key="itemart.id"  class="row w-100 ml-2 mb-2">
                                                        <div class="card  w-100 border border-info">
                                                          <div class="CardBady">
                                                            <div class="row no-gutters align-items-center">
                                                              <div class="col">

                                                                    <b-button size="sm" block  class="pt-2 pb-2 text-secondary" variant="outline-light" @click="Article_click(itemart)">
                                                                        <div class="h6 font-weight-normal text-danger  NonCard mb-1">{{itemart.ref}} </div>
                                                                        <div class="h6 mb-0 font-weight-light text-secondary">{{itemart.description}}</div>

                                                                        <div class="h6 font-weight-normal text-info  NonCard mb-1">Prix : {{itemart.prix_vent}} </div>
                                                                    </b-button>

                                                              </div>

                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
          </div>

          <div class="col">

          <div class="row mb-3">
            <div class="col">
              <div class="card border border-info  h-100">
                <div class="card-body p-0">

            <div class="row m-0 pt-1">

               <h5 class="col p-0 d-flex justify-content-center  font-weight-normal text-secondary">{{itemFormBon.agriculteur_nom}}-{{itemFormBon.agriculteur_nomAR}}-  <span  class="h6 font-weight-light text-danger">{{Uiabonne}}</span>  - {{itemFormBon.mois_nom}}</h5> 

            </div>  







                </div>
              </div>
            </div>

          </div>

                    <form @submit.prevent="handleSubmit" >
                    <div class="form-group">
                            <b-form-datepicker
                            class=" form-control form-control-sm mb-0"
                            id="datepicker-dateformat2"
                            placeholder="Choose a date"
                            v-model="itemForm.date"
                            :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                            locale="fr"
                            :class="{ 'is-invalid': isSubmitted && $v.itemForm.date.$error }" />
                        <div v-if="isSubmitted && !$v.itemForm.date.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                    </div>

                    <div class="row">
                    <div class="form-group col">
                        <label for="nom" class="form-control-sm mb-0">Référence :</label>
                        <input type="text" v-model="itemForm.nom" id="nom" name="nom" class="form-control form-control-sm"
                            :class="{ 'is-invalid': isSubmitted && $v.itemForm.nom.$error }" />
                        <div v-if="isSubmitted && !$v.itemForm.nom.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                    </div>



                    <div class="form-group col">
                        <label for="prix" class="form-control-sm mb-0">Prix TTC : </label>
                        <input type="text" v-model="itemForm.prix" id="prix" name="prix" class="form-control form-control-sm"
                            :class="{ 'is-invalid': isSubmitted && $v.itemForm.prix.$error }" />
                        <div v-if="isSubmitted && !$v.itemForm.prix.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                        <div v-if="isSubmitted && !$v.itemForm.prix.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
                    </div>

                    </div>

                    <div class="row"> 
                    <div class="form-group col">

                        <label for="lot_id" class="form-control-sm mb-0">Lot :</label>

                        <select class="form-control form-control-sm" id="lot_id" v-model="itemForm.lot_id"
                        :class="{ 'is-invalid': isSubmitted && $v.itemForm.lot_id.$error }">
                        <option v-for="item in ListLots" :key="item.id" v-bind:value="item.id">   
                        {{ item.nom }} - {{ item.qte }}
                        </option>
                        </select>
                        <div v-if="isSubmitted && !$v.itemForm.lot_id.required" class="invalid-feedback">Ce champ est obligatoire !!</div>


                    </div>


                    <div class="form-group col">
                        <label for="qte_min" class="form-control-sm mb-0">Quantité : </label>
                        <input ref="qte_field" type="text" v-model="itemForm.qte" id="qte_min" name="qte_min" class="form-control form-control-sm"
                            :class="{ 'is-invalid': isSubmitted && $v.itemForm.qte.$error }" />
                        <div v-if="isSubmitted && !$v.itemForm.qte.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                        <div v-if="isSubmitted && !$v.itemForm.qte.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
                    </div>

                    </div>




                    <div class="form-group">
                    <textarea name="description" id="description"  rows="2"  placeholder="Description ..." class="form-control form-control-sm" v-model="itemForm.description"></textarea>
                    </div>

                    <div class="form-group">
                        <button type="submit" class="btn btn-secondary btn-block">Ajouter Article/Produit</button>
                    </div>

                    </form>

                
                    <b-table 
                    sort-icon-left
                    small 
                    responsive 
                    :fields="fieldsArticle" 
                    :items="ArticlesList"
                    >
                        <template #cell(actions)="row">
                                <b-button pill size="sm"  class="mr-2 float-right" variant="outline-danger" @click="del_click(row.item)">
                                            <i class="fa fa-trash-alt"></i>
                                    
                                </b-button>
                        </template>
                    
                    </b-table>
          </div>

          </div>

<!-- parti end service -->




        </div>   


</div> 

    <!-- les trucs public -->
    
      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>

 </div>


  
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required,decimal} from "vuelidate/lib/validators";


export default {
props: ['ResivedDepot'],
computed: {
    user_name() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.name;
      }
      else
      {
            return '';
      }
    },
    filteragrie() {
            return this.agriculteursList.filter(agrie => {

            if(this.isCINBarreSearch)
            {
                if(agrie.cin)
                {
                return agrie.cin.toLowerCase().includes(this.serchAgri.toLowerCase())
                }

            }
            else
            {

                if(agrie.nom)
                {
                return agrie.nom.toLowerCase().includes(this.serchAgri.toLowerCase())
                }


            }

      })
    }
    },
    mounted(){


                this.fetchCentreDep()
                //current month
                let mm= new Date().getMonth()
                mm++
                this.itemFormBon.mois = mm

                this.article_fetch()

    },
    data () {
		return {
            listArtCaisse:[],
            itemForm: {
                    nom:'',
                    code_bar:'',
                    qte:'',
                    prix:'',
                    date: new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    description:'',
                    article_id:'',
                    lot_id:''},

            itemFormBon: {
                    centre_id:'',
                    centre_nom:'',
                    depot_id:'',
                    depot_nom:'',
                    agriculteur_id:'',
                    agriculteur_nom:'',
                    agriculteur_nomAR:'',
                    mois:'',
                    mois_nom:'',
                    mois_nomAR:'',
                    abon:0,
                    cin:'',
            },

            centres:[],
            depots:[],
            agriculteursList:[],
            isSubmitted: false,
            isLoading: false,
            isCINBarreSearch: false,
            serchAgri:'',
            Uiabonne:'',

            fieldsArticle: [
                {key: 'nom', label: 'Réf'},
                {key: 'qte', label: 'Quantité'},
                {key: 'prix', label: 'Prix'},
                {key: 'date', label: 'Date'},
                {key: 'description', label: 'Description'},
                { key: 'actions', label: '' }
            ],
            ListLots:[],
            ArticlesList:[],


		}
    },
    validations: {
            itemForm: {
                nom: {
                    required
                },
                qte: {
                    required,
                    decimal
                },
                prix: {
                    required,
                    decimal
                },
                date: {
                    required
                },
                article_id: {
                    required
                },
                lot_id: {
                    required
                }
            }

    },
    methods:{
        fetchListArts(id_agri, moiss)
        {
                    this.isLoading=true;
                    this.$http.get('/servicesParAgriDep/'+id_agri+'/'+moiss+'/'+this.ResivedDepot)
                    .then((result) => {
                                this.ArticlesList = result.data.data
                                this.isLoading=false;

                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });


        },
        handleSubmit(){


                //set nom lot
                if(this.itemForm.lot_id)
                {
                let am = this.ListLots.findIndex(item => item.id === this.itemForm.lot_id)
                this.itemForm.nom_lot= this.ListLots[am].nom
                }

                let mm = new Date(this.itemForm.date).getMonth()
                mm++
                if(mm==this.itemFormBon.mois)
                {


                this.add_service()
                }
                else
                {
                                this.flashMessage.show({
                                status: 'info',
                                title: 'Date Hors Mois',
                                message: 'Sélectionnez une date dans le mois sélectionné .. Réessayez !!'
                                })
                }



        },
        add_service()
        {
            

                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {

                            this.isLoading=true;

                            this.$http.post('/serviceAgris',{
                            nom:this.itemForm.nom,
                            qte:this.itemForm.qte,
                            prix:this.itemForm.prix,
                            date:this.itemForm.date,
                            description:this.itemForm.description,
                            agriculteur_id: this.itemFormBon.agriculteur_id,
                            centre_id:this.itemFormBon.centre_id,
                            article_id:this.itemForm.article_id,
                            depot_id:this.ResivedDepot,
                            lot_id:this.itemForm.lot_id

                                })
                                .then(response => {

                                    this.isLoading=false;
                                    this.ArticlesList.unshift(response.data.data);

                                    //desactiver la validation:
                                    this.isSubmitted=false
                                    //afficher message
                                    this.flashMessage.show({
                                    status: 'success',
                                    title: 'Données sauvegardées',
                                    message: 'Les données sont sauvegardées correctement '
                                })


                                })
                                .catch(error=> {console.log(error)
                                this.isLoading=false;


                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur De Sauvegarde',
                                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                                })
                                });
                           

                }
        },
        del_click(itemA){

            this.isLoading=true;

            this.$http.delete('/serviceAgris/'+itemA.id)
                .then(response => {
               this.isLoading=false; 

        console.log(response)

               if(response.data.data =="ok")
               {

  
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })
                    let index = this.ArticlesList.findIndex(item => item.id === itemA.id)

                    this.ArticlesList.splice(index, 1)
               }
               else
               {
                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
               }

                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });

        },


        Article_click(item)
        {
            this.itemForm.nom= item.ref
            this.itemForm.prix= item.prix_vent
            this.itemForm.qte=1
            this.itemForm.article_id = item.article_id
            this.itemForm.description=item.description

           this.fetchLots(item.article_id)
            
            
           this.$refs.qte_field.focus();

        },
        fetchLots(id_art) {
                    this.isLoading=true;
                    this.$http.get('/lotsArtDep/'+id_art+'/'+this.ResivedDepot)
                    .then((result) => {
                                this.ListLots = result.data.data
                                this.isLoading=false;


                                if(this.ListLots.length>0)
                                {
                                        this.itemForm.lot_id=this.ListLots[0].id
                                }
                                else
                                {
                                        this.itemForm.lot_id=''
                                }
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

            //lot desti


        },
        article_fetch()
        {
                    this.isLoading=true;
                    this.$http.get('/posArtcat/0')
                    .then((result) => {
                                this.listArtCaisse = result.data.data                                
                                this.isLoading=false;

                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

        },
        fetchCentreDep: function () {

                    this.isLoading=true;
                    this.$http.get('/centres')
                    .then((result) => {
                                this.centres = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible de charger les données.. Actualisez et Réessayez'
                                })
                    });

        },
        handleSubmitBonCMD(){

                this.isSubmitted = true;

                if (this.itemFormBon.centre_id && this.itemFormBon.mois) {
                 this.fetchListAgriculteurs(this.itemFormBon.centre_id)
                }
                else 
                {
               this.flashMessage.show({
                status: 'info',
                title: 'Sélectionnez un centre et vérifiez les données et Reésayez !!',
                })


                }


        },
        fetchListAgriculteurs(id_agri) {
                    this.isLoading=true;
                    this.$http.get('/agriCentreMin/'+id_agri)
                    .then((result) => {
                                this.agriculteursList = result.data.data
                                this.isLoading=false;

                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible de charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
        agriculteur_click(id_agri)
        {

            let agr = this.agriculteursList.findIndex(item => item.id === id_agri)
            this.itemFormBon.agriculteur_id=this.agriculteursList[agr].id
            this.itemFormBon.abon=this.agriculteursList[agr].abonne

            this.itemFormBon.cin=this.agriculteursList[agr].cin
            this.itemFormBon.agriculteur_nom=this.agriculteursList[agr].nom
            this.itemFormBon.agriculteur_nomAR=this.agriculteursList[agr].nom_ar

                if(this.itemFormBon.abon==1)
                {
                this.Uiabonne="(Adhérent)"
                }
                else
                {
                this.Uiabonne=""
                }





            //get name: 
            this.getMoisName(this.itemFormBon.mois)
            
            this.fetchListArts(this.itemFormBon.agriculteur_id,this.itemFormBon.mois)

        },       
        getMoisName(mm)
        {
                        if (mm==1)
                        {
                        this.itemFormBon.mois_nom='Janvier'

                        this.itemFormBon.mois_nomAR='يناير'
                        }
                        else if (mm==2)
                        {
                        this.itemFormBon.mois_nom='Février'
                        this.itemFormBon.mois_nomAR='فبراير'
                        }
                        else if (mm==3)
                        {
                        this.itemFormBon.mois_nom='Mars'

                        this.itemFormBon.mois_nomAR='مارس'
                        }
                        else if (mm==4)
                        {
                        this.itemFormBon.mois_nom='Avril'

                        this.itemFormBon.mois_nomAR='ابريل'
                        }
                        else if (mm==5)
                        {
                        this.itemFormBon.mois_nom='Mai'

                        this.itemFormBon.mois_nomAR='ماي'
                        }
                        else if (mm==6)
                        {
                        this.itemFormBon.mois_nom='Juin'

                        this.itemFormBon.mois_nomAR='يونيو'
                        }
                        else if (mm==7)
                        {
                        this.itemFormBon.mois_nom='Juillet'

                        this.itemFormBon.mois_nomAR='يليوز'
                        }
                        else if (mm==8)
                        {
                        this.itemFormBon.mois_nom='Août'

                        this.itemFormBon.mois_nomAR='غشت'
                        }
                        else if (mm==9)
                        {
                        this.itemFormBon.mois_nom='Septembre'

                        this.itemFormBon.mois_nomAR='شتنبر'
                        }
                        else if (mm==10)
                        {
                        this.itemFormBon.mois_nom='Octobre'

                        this.itemFormBon.mois_nomAR='اكتوبر'
                        }
                        else if (mm==11)
                        {
                        this.itemFormBon.mois_nom='Novembre'

                        this.itemFormBon.mois_nomAR='نونبر'
                        }
                        else if (mm==12)
                        {
                        this.itemFormBon.mois_nom='Décembre'

                        this.itemFormBon.mois_nomAR='دجنبر'
                        }
                        else
                        {
                        this.itemFormBon.mois_nom='--';
                        this.itemFormBon.mois_nomAR='--'
                        }

        },


},
components: {
              Loading      
}


}
</script>

<style>
.Pgheader {
  background-color: blue;
  opacity: 0.3;
}
.BgSearch {
  background-color:#BEE5EB;
  opacity: 0.9;
}
.BgArticle {
  background-color:#edeeef;
  opacity: 0.8;
}
</style>