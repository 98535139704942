<template>
          <!-- Begin Page Content -->
        <div class=" h-100 overflow-auto">



<div class="row p-0 h-100 overflow-auto m-0">
        <div class="col box overflow-auto p-0 m-0">


                <div class="topRow card  bg-white border  mr-0 ml-0 mt-0 mb-0 p-0 overflow-auto ">
                        <div class="row pt-2 pb-0 border-bottom border-info m-0">

                        <div class="col-5">
                                <h5 class="font-weight-lighter text-info"> <span class="fa fa-cash-register"></span> {{ResivedNom}}</h5> 


                                <div class="mt-1 d-inline text-secondary">N° : 
                                    <span class="d-inline text-info mr-4"> {{itemFormcmd.nom}}             </span>  
                                    
                                    Client : 
                                    <span class="d-inline text-info"> {{itemFormcmd.client}} </span>  
                                </div>


                            </div>



                        <div class="col">


                                        <b-button v-if="this.ResivedId"  pill size="sm"  class="m-2 p-1 float-right" variant="outline-primary" @click="addCmd">
                                            
                                            <span>   Nouvelle Commande   </span> 
                                        </b-button>

                                        <b-button v-if="itemForm.pos_cmd_id" pill size="sm"  class="m-2 p-1 float-right" variant="outline-danger" @click="dellCmd">
                                            
                                            <span>   Supprimer   </span> 
                                        </b-button>

                                        <b-button  v-if="itemForm.pos_cmd_id" pill size="sm"  class="m-2 p-1 float-right" variant="outline-success" @click="validerBon">
                                            <span>  Valider   </span> 
                                        </b-button>
                            </div>


                            
                        </div>




                                                            <div class="row m-0 pt-2">
                                                            <div v-for="itemart in listArtCaisse" :key="itemart.id"  class="col-xl-3 col-md-6 mb-4">
                                                                <div class="card border-left-primary shadow h-100 ">
                                                                <div class="CardBady  h-100">

                                                                            <b-button size="sm" block  class="pt-2 pb-2 text-secondary h-100" variant="outline-light" @click="Article_click(itemart)">
                                                                                <div class="h6 font-weight-normal text-danger  NonCard mb-1">{{itemart.ref}} </div>
                                                                                <div class="h6 mb-0 font-weight-light text-secondary">{{itemart.description}}</div>

                                                                                <div class="h6 font-weight-normal text-info  NonCard mb-1">Prix : {{itemart.prix_vent}} </div>
                                                                            </b-button>
                                                                </div>
                                                                </div>
                                                            </div>
                                                            </div>
                            </div>


                            <div class="bottomRow card  border bg-white mr-0 ml-0 mb-0 mt-3 p-2 overflow-auto">
                                        <div class="row m-0">
                                            <div v-for="itemcat in listCatCaisse" :key="itemcat.id"  class="col-xl-3 col-md-4 mb-4">
                                            <div class="card border-left-primary shadow h-100 ">
                                                <div class="CardBady  h-100">

                                                        <b-button size="sm" block  class="pt-3 pb-3 text-secondary  h-100" variant="outline-light" @click="Category_click(itemcat)">
                                                            <div class="h6 font-weight-normal text-danger  NonCard mb-1">{{itemcat.nom}} </div>
                                                        </b-button>


                                                </div>
                                            </div>
                                            </div>
                                        </div>
                            </div>
                    


        </div>
        <div v-if="itemForm.pos_cmd_id" class="col-4 h-100 overflow-auto p-0">
                <div class="card  border  h-100 overflow-auto p-0 ml-3">

                    
                                <div class="row m-0">

                                <h5 class="col p-0 d-flex justify-content-center mt-2 font-weight-lighter text-success">  Total  :  {{total_price}} DH</h5> 

                                </div>   

                            <form @submit.prevent="handleSubmit" class="p-2">

                                <div class="row m-0">
                                                    <div class="form-group col mb-0 mr-2 p-0">
                                                        <label for="nom" class="form-control-sm mb-0">Référence :</label>
                                                        <input type="text" v-model="itemForm.ref" id="ref" name="ref" class="form-control form-control-sm"
                                                            :class="{ 'is-invalid': isSubmitted && $v.itemForm.ref.$error }" />
                                                        <div v-if="isSubmitted && !$v.itemForm.ref.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                                                    </div>


                                                    <div class="form-group col m-0 p-0">

                                                        <label for="lot_id" class="form-control-sm mb-0">Lot :</label>

                                                        <select class="form-control form-control-sm" id="lot_id" v-model="itemForm.lot_id"
                                                        :class="{ 'is-invalid': isSubmitted && $v.itemForm.lot_id.$error }">
                                                        <option v-for="item in ListLots" :key="item.id" v-bind:value="item.id">   
                                                        {{ item.nom }} - {{ item.qte }}
                                                        </option>
                                                        </select>
                                                        <div v-if="isSubmitted && !$v.itemForm.lot_id.required" class="invalid-feedback">Ce champ est obligatoire !!</div>


                                                    </div>
                                </div>
                                <div class="row m-0">
                                                    <div class="form-group col mb-0 mr-2 p-0">
                                                        <label for="qte" class="form-control-sm mb-0">Quantité : </label>
                                                        <input ref="qte_field" type="text" v-model="itemForm.qte" id="qte" name="qte" class="form-control form-control-sm"
                                                            :class="{ 'is-invalid': isSubmitted && $v.itemForm.qte.$error }" />
                                                        <div v-if="isSubmitted && !$v.itemForm.qte.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                                                        <div v-if="isSubmitted && !$v.itemForm.qte.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
                                                    </div>


                                                    <div class="form-group col m-0 p-0">
                                                        <label for="prix" class="form-control-sm mb-0">Prix TTC : </label>
                                                        <input type="text" v-model="itemForm.prix" id="prix" name="prix" class="form-control form-control-sm"
                                                            :class="{ 'is-invalid': isSubmitted && $v.itemForm.prix.$error }" />
                                                        <div v-if="isSubmitted && !$v.itemForm.prix.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                                                        <div v-if="isSubmitted && !$v.itemForm.prix.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
                                                    </div>
                                </div>
                            <div class="form-group mt-2">
                            <textarea name="description" id="description"  rows="2"  placeholder="Description ..." class="form-control form-control-sm" v-model="itemForm.description"></textarea>
                            </div>

                            <div class="form-group">
                                <button type="submit" class="btn btn-secondary btn-block">Ajouter Article/Produit</button>
                            </div>
                            </form>


                            
                        <b-table 
                        sort-icon-left
                        small 
                        responsive 
                        :fields="fieldsArticle" 
                        :items="ArticlesList"
                        >
                            <template #cell(actions)="row">
                                    <b-button pill size="sm"  class="mr-2 float-right" variant="outline-danger" @click="del_click(row.item)">
                                                <i class="fa fa-trash-alt"></i>
                                        
                                    </b-button>
                            </template>
                        
                        </b-table>

                </div>


        </div>

 </div>




    <!-- le modal -->
    <b-modal id="cmdModal" title="Nouvelle Commande"  hide-footer>
            <form @submit.prevent="handleSubmitCmd">
                            <div class="form-group">
                            <label for="bank_compte_id">Date :</label>
                            <div class="form-group">
                                    <b-form-datepicker
                                    class=" form-control form-control-sm mb-0"
                                    id="datepicker-dateformat2"
                                    placeholder="Choose a date"
                                    v-model="itemFormcmd.date"
                                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                                    locale="fr"/>

                            </div>


                      <div class="form-group ">
                          <label for="prix" class="form-control-sm mb-0">Client : </label>
                          <input type="text" v-model="itemFormcmd.client" id="prix" name="prix" class="form-control form-control-sm" />

                      </div>

                      <div class="form-group">
                      <textarea name="description" id="description"  rows="2"  placeholder="Description ..." class="form-control form-control-sm" v-model="itemFormcmd.description"></textarea>
                      </div>

            </div>


            <div class="form-group">
                <button type="submit" class="btn  btn-primary btn-block">Valider</button>
            </div>
            </form>  

    </b-modal>





    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>


        </div>
        <!-- /.container-fluid -->

  
      <!-- End of Main Content -->

</template>


<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required,decimal} from "vuelidate/lib/validators";

export default {
    props: ['ResivedId','ResivedDepot','ResivedNom','ResivedCmdId'],
    computed: {
            user_name() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.name;
      }
      else
      {
            return '';
      }
    },
    total_price: function() {
      return this.ArticlesList.reduce((a, b) => +a + +b.qte * b.prix , 0);
    },
 
    },
    mounted(){

        if(this.ResivedId && this.ResivedDepot)
        {
            this.fetchList()

                if(this.ResivedCmdId)
                {
                    this.fetchListArts(this.ResivedCmdId)
                }
        }

    },
	data () {
		return {

             listCatCaisse: [],
             listArtCaisse: [],
            itemForm: {
                    ref:'',
                    qte:'',
                    prix:'',
                    description:'',
                    article_id:'',
                    lot_id:'',
                    pos_cmd_id:'',},

            fieldsArticle: [
                {key: 'ref', label: 'Réf'},
                {key: 'qte', label: 'Qté'},
                {key: 'prix', label: 'Prix'},
                { key: 'actions', label: '' }
            ],
            ListLots:[],
            ArticlesList:[],

            itemFormcmd: {
                    nom:'',
                    date: new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    client:'',
                    description:'',
                    },







            isLoading: false
		}
	},
      validations: {
            itemForm: {
                ref: {
                    required
                },
                qte: {
                    required,
                    decimal
                },
                prix: {
                    required,
                    decimal
                },
                article_id: {
                    required
                },
                lot_id: {
                    required
                },
                pos_cmd_id: {
                    required
                }
            }

    },
    methods :{
        Category_click(item)
        {
                    this.isLoading=true;
                    this.$http.get('/posArtcat/'+item.id)
                    .then((result) => {
                                this.listArtCaisse = result.data.data                                
                                this.isLoading=false;

                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

        },
        Article_click(item)
        {
            this.itemForm.ref= item.ref
            this.itemForm.prix= item.prix_vent
            this.itemForm.qte=1
            this.itemForm.article_id = item.article_id
            this.itemForm.description=item.description

           this.fetchLots(item.article_id)
            
            
           this.$refs.qte_field.focus();

        },
        fetchLots(id_art) {
                    this.isLoading=true;
                    this.$http.get('/lotsArtDep/'+id_art+'/'+this.ResivedDepot)
                    .then((result) => {
                                this.ListLots = result.data.data
                                this.isLoading=false;


                                if(this.ListLots.length>0)
                                {
                                        this.itemForm.lot_id=this.ListLots[0].id
                                }
                                else
                                {
                                        this.itemForm.lot_id=''
                                }
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

            //lot desti


        },
        fetchList: function () {

                    this.isLoading=true;
                    this.$http.get('/posCategors')
                    .then((result) => {
                                this.listCatCaisse = result.data.data
                                this.isLoading=false;

                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },

        handleSubmit(){

            

                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {

                            this.isLoading=true;

                            this.$http.post('/posVent',{
                            ref:this.itemForm.ref,
                            qte:this.itemForm.qte,
                            prix:this.itemForm.prix,
                            description:this.itemForm.description,
                            article_id:this.itemForm.article_id,
                            depot_id:this.ResivedDepot,
                            lot_id:this.itemForm.lot_id,
                            pos_cmd_id:this.itemForm.pos_cmd_id

                                })
                                .then(response => {

                                    this.isLoading=false;
                                    this.ArticlesList.unshift(response.data.data);

                                    //initialiser les champs la form
                                    this.itemForm.ref=''
                                    this.itemForm.qte=''
                                    this.itemForm.prix=''
                                    this.itemForm.description=''
                                    this.itemForm.article_id=''
                                    this.itemForm.lot_id=''

                                    //desactiver la validation:
                                    this.isSubmitted=false
                                    //afficher message
                                    this.flashMessage.show({
                                    status: 'success',
                                    title: 'Données sauvegardées',
                                    message: 'Les données sont sauvegardées correctement '
                                })


                                })
                                .catch(error=> {console.log(error)
                                this.isLoading=false;


                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur De Sauvegarde',
                                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                                })
                                });
                           

                }




        },
        del_click(itemA){

            this.isLoading=true;

            this.$http.delete('/posVent/'+itemA.id)
                .then(response => {
               this.isLoading=false; 

                console.log(response)

               if(response.data.data =="ok")
               {

  
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })
                    let index = this.ArticlesList.findIndex(item => item.id === itemA.id)

                    this.ArticlesList.splice(index, 1)
               }
               else
               {
                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
               }

                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });

        },
//CMD
        addCmd()
        {
        this.itemFormcmd.date= new Date().toJSON().slice(0,10).replace(/-/g,'-')
        this.itemFormcmd.client="Par Défaut"
        this.$root.$emit('bv::show::modal', 'cmdModal')
        },
        handleSubmitCmd()
        {
                              //initialiser
                            this.itemForm.pos_cmd_id ='';

                            this.isLoading=true;

                            this.$http.post('/posCmd',{
                            date:this.itemFormcmd.date,
                            etat:0,
                            client:this.itemFormcmd.client,
                            description:this.itemFormcmd.description,
                            user:this.user_name,
                            pos_caise_id:this.ResivedId

                                })
                                .then(response => {

                                    this.isLoading=false;
                                    //this.ArticlesList.unshift(response.data.data);
                                    this.itemForm.pos_cmd_id = response.data.data.id
                                    this.itemFormcmd.nom = response.data.data.nom
                                    //desactiver la validation:
                                    this.isSubmitted=false



                                     this.$bvModal.hide('cmdModal')
                                    //afficher message
                                    this.flashMessage.show({
                                    status: 'success',
                                    title: 'Données sauvegardées',
                                    message: 'Les données sont sauvegardées correctement '
                                })


                                })
                                .catch(error=> {console.log(error)


                                this.itemForm.pos_cmd_id ='';
                                this.isLoading=false;


                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur De Sauvegarde',
                                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                                })
                                });

        },
        dellCmd()
        {
      
                                      this.$confirm("Voulez-vous vraiment supprimer cette commande ??")
                                .then(() => {

                                                    this.isLoading=true;

                                                    this.$http.delete('/posCmd/'+this.itemForm.pos_cmd_id)
                                                        .then(response => {
                                                    this.isLoading=false; 

                                                        console.log(response)

                                                        this.itemForm.pos_cmd_id=''
                                                        this.itemFormcmd.nom=''
                                                        this.itemFormcmd.client=''
                                                        this.ArticlesList=[]




                                                        //initialiser les champs la form
                                                        this.itemForm.ref=''
                                                        this.itemForm.qte=''
                                                        this.itemForm.prix=''
                                                        this.itemForm.description=''
                                                        this.itemForm.article_id=''
                                                        this.itemForm.lot_id=''




                                                        this.flashMessage.show({
                                                        status: 'success',
                                                        title: 'Données Supprimées',
                                                        message: 'Les données sont supprimées correctement'
                                                        })

                                                        })
                                                        .catch(error=> {console.log(error)
                                                        this.isLoading=false;


                                                        this.flashMessage.show({
                                                        status: 'error',
                                                        title: 'Erreur De Suppression',
                                                        message: 'Impossible  de supprimer les données .. Réessayez !!'
                                                        })
                                                        });
                                })
                                .catch(error=> {
                                console.log(error)

                                });





        },
        validerBon()
        {

                                this.$confirm("Voulez-vous vraiment VALIDER cette commande ??")
                                .then(() => {

                                                this.isLoading=true;

                                                this.$http.get('/validCmd/'+this.itemForm.pos_cmd_id+'/1')
                                                    .then(response => {
                                                    console.log(response.data)
                                                    this.isLoading=false;   
                                                    this.flashMessage.show({
                                                    status: 'success',
                                                    title: 'Bon valider',
                                                    message: 'Les données sont validées correctement'
                                                    })

                                                    this.itemForm.pos_cmd_id=''
                                                    this.itemFormcmd.nom=''
                                                    this.itemFormcmd.client=''
                                                    this.ArticlesList=[]

                                                         //initialiser les champs la form
                                                        this.itemForm.ref=''
                                                        this.itemForm.qte=''
                                                        this.itemForm.prix=''
                                                        this.itemForm.description=''
                                                        this.itemForm.article_id=''
                                                        this.itemForm.lot_id=''


                                                        })
                                                    .catch(error=> {console.log(error)
                                                    this.isLoading=false;


                                                    this.flashMessage.show({
                                                    status: 'error',
                                                    title: 'Erreur De Validation',
                                                    message: 'Impossible  de valider les données .. Réessayez !!'
                                                    })
                                                    });
                                })
                                .catch(error=> {
                                console.log(error)
                                });



        },
        fetchListArts(id_bn)
        {


                    this.isLoading=true;
                    this.$http.get('/posCmd/'+id_bn)
                    .then((result) => {
                    
                    //affectation des information
                                    this.itemForm.pos_cmd_id = result.data.data.id
                                    this.itemFormcmd.nom = result.data.data.nom
                                    this.itemFormcmd.client = result.data.data.client


                     //desible load
                     this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });


                    this.isLoading=true;
                    this.$http.get('/ventCmd/'+id_bn)
                    .then((result) => {
                                this.ArticlesList = result.data.data
                                this.isLoading=false;

                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });


        },
    },
    components: {
              Loading      
    }
}
</script>


<style>
.box {
  display: flex;
  flex-flow: column;
  height: 100%;
}
.topRow {

   flex: 1 1 auto;
    width: 100%;
}
.bottomRow {

    flex: 0 1 35%;
    width: 100%;
}
.BgNotifIcon {
  color: #ff793f;
  opacity: 0.9;
}
.NonCard {
  color: #d35400;
  opacity: 0.9;
}
.CardBady {
  padding: 0;
}

</style>